<template>
  <v-btn
      v-if="clickStop"
      @click.stop="$emit('click')"
      :to="to || null"
      :color="buttonColor"
      :elevation="elevation"
      :height="height"
      :outlined="outlined"
      :dark="isDark && !disabled"
      :text="text"
      class="noCaps"
      :class="[{'justify-start' : left }]"
      :block="block"
      :loading="loading"
      :disabled="disabled"
      :min-width="minWidth"
      :target="!newWindow ? '_self' : '_blank'"
      :href="href"
      :style="textRegular ? `padding : 0 8px` : ''"
      :rounded="rounded"
  >
    <slot>
      <v-icon
          v-if="leftIcon"
          class="mr-2"
          :color="leftIconColor || null"
      >
        {{ leftIcon }}
      </v-icon>
      <span :is="textRegular ? 'h5' : 'span'" :class="[{'font-weight-regular' : textRegular}]" :style="!textRegular ? 'font-size: 13px' : ''" >
        {{ $t(label) }}
        {{ labelRaw }}
      </span>
      <v-icon
          v-if="icon"
          :class="[{'ml-3' : left}]"
          :color="iconColor || null"
      >
        {{ icon }}
      </v-icon>
    </slot>
  </v-btn>
  <v-btn
      v-else
      @click="$emit('click')"
      :to="to || null"
      :color="buttonColor"
      :elevation="elevation"
      :height="height"
      :outlined="outlined"
      :dark="isDark && !disabled"
      :text="text"
      class="noCaps"
      :class="[{'justify-start' : left }]"
      :block="block"
      :loading="loading"
      :disabled="disabled"
  >
    <slot>
      <v-icon
          v-if="leftIcon"
          class="mr-2"
          :color="leftIconColor || null"
      >
        {{ leftIcon }}
      </v-icon>
      <span style="font-size: 13px" >
        {{ $t(label) }}
        {{ labelRaw }}
      </span>
      <v-icon
          v-if="icon"
          :class="[{'ml-3' : left}]"
          :color="iconColor || null"
      >
        {{ icon }}
      </v-icon>
    </slot>
  </v-btn>
</template>

<script>
export default {
  name: "wsButton",
  props : {
    height : {
      type : String,
      default : '40'
    },
    elevation : {
      type : String,
      default : '0'
    },
    to : {
      type : String,
      default : ''
    },
    color : {
      type : String,
    },
    outlined : {
      type : Boolean,
      default : false
    },
    rounded : {
      type : Boolean,
      default : false
    },
    block : {
      type : Boolean,
      default : false
    },
    dark : {
      type : Boolean,
      default : false
    },
    text : {
      type : Boolean,
      default : false
    },
    label : {
      type : String,
    },
    icon : {
      type : String,
    },
    leftIcon : {
      type : String,
    },
    leftIconColor : {
      type : String,
      default : null
    },
    iconColor : {
      type : String,
      default : null
    },

    labelRaw : {
      type : String,
    },
    left : {
      type : Boolean,
      default : false
    },
    accent : {
      type : Boolean,
      default : false
    },
    disabled : {
      type : Boolean,
      default : false
    },
    loading : {
      type : Boolean,
      default : false
    },
    clickStop : {
      type : Boolean,
      default : true
    },
    minWidth : {
      type : String,
      default : "100"
    },
    newWindow : {
      type : Boolean,
      default : false
    },
    href : {
      type : String,
      default : null
    },
    textRegular : {
      type : Boolean,
      default : false
    }
  },
  computed : {
    isDark() {
      if ( this.text || this.outlined ) {
        return false
      }
      return true
    },
    buttonColor() {
      if ( this.color ) {
        return this.color
      }
      if ( this.accent ) {
        return this.$store.state.colors.wsACCENT
      }
      if ( this.outlined || this.text ) {
        return this.$store.state.colors.wsDARKER
      }

      return this.$store.state.colors.wsATTENTION
    }
  }
}
</script>

<style scoped>

</style>